import React, { KeyboardEvent } from 'react';
import ForgotPassword from '@carrier-io/air-react/patterns/SignIn/ForgotPassword';
import { Box } from '@mui/material';

import { useForgotPassword } from './useForgotPassword';

interface ForgotPasswordFormProps {
  hideCancelButton?: boolean;
  renamedSignInButtonTitle?: string;
}

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  hideCancelButton = false,
  renamedSignInButtonTitle = '',
}) => {
  const { values, status, errors, submitForm, resetForm, handleBlur, setFieldValue } =
    useForgotPassword();

  const handleKeyPress = (event: KeyboardEvent<HTMLElement>): void => {
    if (event.key === 'Enter') {
      submitForm();
    }
  };

  return (
    <Box display="flex">
      <Box sx={{ marginLeft: '0.25em' }}>
        <ForgotPassword
          hideCancelButton={hideCancelButton}
          currentEmail={values.email}
          onSubmit={submitForm}
          onEmailChange={(value) => setFieldValue('email', value.email)}
          emailSuccessfullySent={false}
          labelEntry="Forgot your password?"
          errors={{
            submitAction: status?.type === 'error' && status?.message ? status.message : null,
          }}
          onClose={resetForm}
          cancelButtonProps={{ label: 'Cancel' }}
          submitButtonProps={{
            label: 'Submit',
            disabled: false,
          }}
          typographyModalTitleProps={{ label: 'Recover password' }}
          typographyMainTextProps={{
            label: 'Enter your email address and we will send instructions to reset your password.',
          }}
          textFieldProps={{
            label: 'Email',
            error: !!errors.email,
            helperText: errors.email ?? '',
            onBlur: handleBlur,
            onKeyPress: handleKeyPress,
          }}
          signInButtonProps={{
            label: hideCancelButton ? renamedSignInButtonTitle : 'Submit',
          }}
        />
      </Box>
    </Box>
  );
};
