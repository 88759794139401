import Logo from '@carrier-io/air-react/patterns/Logo/Logo';
import React, { memo } from 'react';

import LoginPageLogoImageAssetUrl from '../assets/login-page-logo.svg';

export const LoginLynxLogo = memo(() => (
  <Logo
    alt="logo"
    href="https://www.carrier.com/truck-trailer/en/eu/service-support/LYNX_fleet/"
    src={LoginPageLogoImageAssetUrl}
    sx={{
      marginBottom: '32px',
      marginTop: '32px',
    }}
  />
));

export default LoginLynxLogo;
