import React, { useState, MouseEvent, memo } from 'react';
import Typography from '@carrier-io/air-react/Typography';
// import Divider from '@carrier-io/air-react/Divider';
// import {Button, Typography, Popover, Avatar } from '@mui/material';
import Button from '@carrier-io/air-react/Button';
import Divider from '@carrier-io/air-react/Divider';
import Avatar from '@carrier-io/air-react/Avatar';
import Popover from '@carrier-io/air-react/Popover';

import { DrawerHeader } from './DrawerHeader';
import { UserNavigation } from './UserNavigation';

// import { ExpiredAssetsModal } from './ExpiredAssetsModal';

// import { getAuthUserGroups } from '@/features/authentication';
// import { useAppDispatch, useAppSelector } from '@/stores';
// import { useUserSettings } from '@/providers/UserSettings';
// import { getOktaUserEmail } from '@/utils';
// import { PrivacyRenderer } from '@/components';
// import './styles.scss';
// import { fetchExpiredAssetForExpiredButton, getExpiredAssets } from '@/features/asset-tracking';

interface Props {
    fullName: string;
    email: string;
}

export const PrivacyRenderer = ({ value }: { value: string }): JSX.Element => (
    <span data-private>{value}</span>
  );

export const UserSettings = memo((props: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
//   const [showExpiredAssetsModal, setShowExpiredAssetsModal] = useState(false);
//   const [showExpiredAssetsButton, setShowExpiredAssetsButton] = useState(false);
//   const { userSettings } = useUserSettings();
  const { fullName } = props;
  const { email} = props;
  const userFullName = fullName ?? '';

  const handleClick = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        variant="text"
        color="secondary"
        sx={{ fontWeight: 600, py: 2, pr: 1}}
        data-testid="user-profile"
      >
        <Typography
          variant="subtitle2"
          sx={{
            color: 'rgba(255, 255, 255, 0.6)',
          }}
        >
          Hi,
          &nbsp;
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: 'rgba(255, 255, 255, 0.87)',
          }}
        >
          {userFullName}
        </Typography>
        <Avatar
          style={{
            background: '#c9f7f5',
            color: '#1bc5bd',
            marginLeft: '0.75rem',
            width: '32px',
            height: '32px',
            borderRadius: '50%',
          }}
          sx={{ fontSize: 'subtitle1.fontSize' }}
        >
          {userFullName[0] ?? ''}
        </Avatar>
      </Button>
      <Popover
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            filter: (theme) => `drop-shadow(0px 2px 8px ${theme.palette.action.active})`,
            width: 352,
            p: 2,
            minHeight: '78%',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <DrawerHeader fullName={userFullName} email={email} />
        <Divider sx={{ mx: -2 }} />
        <UserNavigation />
      </Popover>
      
    </>
  );
});

export default UserSettings;
