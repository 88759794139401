import React, { FC, ReactElement, ReactNode, CSSProperties } from 'react';
import Modal, { ModalProps } from '@carrier-io/air-react/Modal';
// import { ModalProps } from '@mui/material/Modal';
import ModalActions from '@carrier-io/air-react/ModalActions';
// import { SxProps, Tooltip} from '@mui/material';
import ModalContent from '@carrier-io/air-react/ModalContent';
import ModalTitle from '@carrier-io/air-react/ModalTitle';

import { SxProps, Box, Typography, Tooltip } from '@mui/material';
// import Tooltip from '@carrier-io/air-react/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export interface DialogProps extends Omit<any, 'content'> {
  dialogTitle: string;
  content?: ReactElement;
  actions?: ReactElement;
  dividers?: boolean;
  contentSx?: SxProps;
  styleContent?: CSSProperties;
  actionsSx?: SxProps;
  dialogTitleSx?: SxProps;
  tooltipTitle?: NonNullable<ReactNode>;
  open: boolean;
  clearable?: boolean;
}

export const Dialog: FC<DialogProps> = ({
  onClose,
  open,
  dialogTitle,
  content,
  actions,
  dividers,
  contentSx,
  styleContent,
  actionsSx,
  dialogTitleSx,
  tooltipTitle,
  maxWidth,
  clearable = true,
  ...rest
}) => (
   
   
  <Modal onClose={onClose} open={open} maxWidth={maxWidth || 'xs'} {...rest}>
    <ModalTitle
      sx={dialogTitleSx}
      clearable={clearable}
      onClose={() => {
        if (onClose) {
          onClose({}, 'backdropClick');
        }
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="flex-end">
          <Typography sx={{ mr: 1, fontWeight: 600, fontSize: '1.25rem' }} variant="h6">
            {dialogTitle}
          </Typography>
          {/* {tooltipTitle && (
            <Tooltip title='test-too-tip'>
              <InfoOutlinedIcon fontSize="small" sx={{ mb: 0.25 }} />
            </Tooltip>
          )} */}
        </Box>
      </Box>
    </ModalTitle>
    <ModalContent sx={contentSx} style={styleContent} dividers={dividers}>
      {content}
    </ModalContent>
    {actions && <ModalActions sx={actionsSx || { padding: 2.5 }}>{actions}</ModalActions>}
  </Modal>
);

export default Dialog;