import React from 'react';
import Box from '@carrier-io/air-react/Box';
import { Drawer, List, ListItem, ListItemText, Toolbar } from '@mui/material';
import Divider from '@carrier-io/air-react/Divider';

const menuItems = ["Over View", "Company Management", "Products Management", "Usage Plans Management"];

export const NavBar: React.FC = () => {
  return (
   <Box sx={{ display: 'flex', flex: 1 }}>
       <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          width: 240,
          flexShrink: 0,
          mt: '64px', 
          [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' },
        }}
      >
      <Toolbar />
      <List sx={{
            borderTop: '1px solid lightgray'
          }}>
        {menuItems.map((text) => (
          <ListItem  button key={text} sx={{
            borderBottom: '1px solid lightgray',
            height: '100px'
          }}>
            <ListItemText primary={text} />
            <Divider />
          </ListItem>
        ))}
      </List>
    </Drawer>
   </Box>
  );
};

export default NavBar;
