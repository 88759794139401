import React, { useState, FC, useCallback } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Typography from '@carrier-io/air-react/Typography';
import ButtonBase from '@carrier-io/air-react/ButtonBase';
import { Snackbar } from '@mui/material';



import { useAppDispatch } from '../utils/useAppDispatch';
import { showMessage } from '../utils/showMessage';

interface CopyToClipboardProps {
  label: string;
  text: string;
}

export const CopyToClipboard: FC<CopyToClipboardProps> = ({ label, text }) => {
 
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const copyKeyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Copied'); // Log success message
      setOpenSnackbar(true); // Show success message in a Snackbar
    });
  }, [text]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
    <ButtonBase onClick={copyKeyToClipboard}>
      <ContentCopyIcon sx={{ marginRight: '.5rem', color: 'primary.main' }} />
      <Typography color="primary" variant="buttonMedium">
        {label}
      </Typography>
    </ButtonBase>
    <Snackbar
    open={openSnackbar}
    autoHideDuration={2000}
    onClose={handleCloseSnackbar}
    message="Copied to clipboard"
  />
  </>
    
  );
};

export default CopyToClipboard;
