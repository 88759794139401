import React, {useEffect, useState} from "react";
import {Box, Menu, MenuItem, Button, InputBase, Divider} from "@mui/material";
import {KeyboardArrowDown, KeyboardArrowUp, Search} from "@mui/icons-material";
import styles from "../styles/CompanyManagement.module.css";
import TableComponent from "./Table";

const CompanyManagement: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState("TTI");
  const [selectedTab, setSelectedTab] = useState("Companies");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await fetch(
          "https://api.admin.dev.fleet.lynx.carrier.io/tenants",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({limit: 20, nextToken: null}),
          }
        );
        const data = await response.json();
        setCompanies(data.items);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await fetch(
          "https://api.admin.dev.fleet.lynx.carrier.io/users",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({limit: 20, nextToken: null}),
          }
        );
        const data = await response.json();
        setUsers(data.items);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchCompanies();
    fetchUsers();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value: string) => {
    setSelectedOption(value);
    handleClose();
  };

  const companyTableHeader = [
    {name: "Company Name", key: "name"},
    {name: "Region", key: "contactRegion"},
    {name: "Country", key: "contactCountry"},
    {name: "Company Type", key: "companyType"},
    {name: "Company Admin", key: "contactName"},
    {name: "Has Parent", key: "parentId"},
    {name: "Last Modified By", key: "lastModifiedBy"},
    {name: "Last Modified On", key: "lastModified"},
    {name: "Assets", key: "assets"},
    {name: "Users", key: "users"},
  ];

  const userTableHeader = [
    {name: "Name", key: "fullName"},
    {name: "Email", key: "email"},
    {name: "Office Phone Number", key: "phone"},
    {name: "Mobile Phone Number", key: "mobilePhone"},
    {name: "Company Name", key: "name"},
    // {name: "User Group", key: "groups"},
    {name: "Last Visited On", key: "lastModified"},
  ];

  return (
    <Box className={styles.companyManagement}>
      <Box className={styles.headerBox}>
        <Button
          onClick={handleClick}
          sx={{
            color: "rgb(66, 66, 66)",
            fontWeight: "bold",
          }}
          endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        >
          {selectedOption}
        </Button>

        <InputBase
          placeholder="Search..."
          sx={{
            borderRadius: "4px",
            padding: "4px 8px 4px 48px",
            ml: 2,
            backgroundColor: "#f0f0f0",
          }}
          startAdornment={
            <Search
              sx={{
                position: "absolute",
                left: "8px",
                top: "50%",
                transform: "translateY(-50%)",
                color: "action.active",
              }}
            />
          }
        />

        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem onClick={() => handleMenuItemClick("TTI")}>TTI</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("LFC")}>LFC</MenuItem>
        </Menu>
      </Box>

      <Box className={styles.groups}>
        <Box className={styles.tabs}>
          <Box
            className={selectedTab === "Companies" ? styles.activeTab : styles.passiveTab}
            onClick={() => setSelectedTab("Companies")}
          >
            Companies (125)
          </Box>
          <Divider orientation="vertical" flexItem sx={{mx: 5, height: 75}} />
          <Box
            className={selectedTab === "Users" ? styles.activeTab : styles.passiveTab}
            onClick={() => setSelectedTab("Users")}
          >
            Users (52)
          </Box>
        </Box>
        <Divider sx={{width: "100%"}} />
      </Box>

      <Box className={styles.tableBox}>
        {selectedTab === "Companies" ? (
          <TableComponent headers={companyTableHeader} data={companies} />
        ) : (
          <TableComponent headers={userTableHeader} data={users} />
        )}
      </Box>
    </Box>
  );
};

export default CompanyManagement;
