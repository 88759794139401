// import Grid from '@carrier-io/air-react/Grid';
// import Typography from '@carrier-io/air-react/Typography';

import React, { FC } from 'react';
// import { useRbac } from '@carrier-io/rbac-provider-react';

import { ExternalApiKeyInput } from './ExternalApiKeyInput';

// import { getAuthUserEmail } from '@/features/authentication';
// import { useAppSelector } from '@/stores';
// import { actionPayload } from '@/features/authorization';

export const ExternalApiKey: FC = () => {
//   const { t } = useTranslation();

//   const { hasPermission } = useRbac();
//   const email = useAppSelector(getAuthUserEmail);

//   const canAccessApiPortal = hasPermission(
//     actionPayload({
//       action: 'apiPortal.view',
//       subjectType: 'USER',
//       subjectId: email,
//     })
//   );

  return  ( <ExternalApiKeyInput />) 
};

export default ExternalApiKey;
