import * as Yup from 'yup';

export const useEmailValidationSchema = (): Yup.StringSchema<
  string,
  Yup.AnyObject,
  undefined,
  ''
> =>
  Yup.string()
    .trim()
    .email('Wrong email format')
    .required('Email is required')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols');
