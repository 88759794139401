// TableComponent.tsx
import React, {memo} from "react";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import styles from "../styles/Table.module.css";

interface TableProps {
  headers: {name: string; key: string}[];
  data: any[];
}

const TableComponent: React.FC<TableProps> = memo(({headers, data}) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableCell key={header.key} className={styles.tabledHeaderCell}>
              <span className={styles.tabledHeaderCellText}>{header.name}</span>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((rowData, index) => (
          <TableRow key={index} className={styles.tableRow}>
            {headers.map((header) => (
              <TableCell>{rowData[header.key]}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
});

export default TableComponent;
