import {createTheme} from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "light",
  },
  typography: {
    fontFamily: '"Nunito Sans", Helvetica, Arial, sans-serif',
  },
});

export default theme;
