import React, { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Box, Typography } from '@mui/material';

import { ChangePasswordDialogView } from './ChangePasswordDialogView';
import { TermsOfServiceAndPrivacyPolicyOptimized } from './TermsOfServiceAndPrivacyPolicyOptimized';
import {TermsOfServiceAndPrivacyPolicy} from './TermsOfServiceAndPrivacyPolicy';
 
interface UseToggle {
  value: boolean;
  toggle: () => void;
  toggleOn: () => void;
  toggleOff: () => void;
}


export const useToggle = (initialValue: boolean): UseToggle => {
    const [value, setValue] = useState(initialValue);
  
    const toggle = useCallback(() => setValue((prevValue: boolean) => !prevValue), []);
  
    const toggleOn = useCallback(() => setValue(true), []);
  
    const toggleOff = useCallback(() => setValue(false), []);
  
    return {
      value,
      toggle,
      toggleOn,
      toggleOff,
    };
  };

export const DrawerFooter: FC = () => {
//   const {
//     supportSessionState: { supportSessionId },
//   } = useSupportSessionContext();
//   const { t } = useTranslation();
//   const { logout } = useLogout();
  const [openChangePassModal, setOpenChangePassModal] = useState(false);
  const navigate = useNavigate(); 
//   const { featureFlags } = useApplicationContext();
//   const isFeatureChangePasswordAvailable = featureFlags.REACT_APP_FEATURE_CHANGE_PASSWORD;
//   const isOneTrust = featureFlags.REACT_APP_FEATURE_ONE_TRUST;

  const {
    value: isPopupOpen,
    toggleOn: handlePopupOpen,
    toggleOff: handlePopupClose,
  } = useToggle(false);

  const handleChangePassword = (): void => {
    setOpenChangePassModal(!openChangePassModal);
  };

 const handleLogout = useCallback(() => {
  navigate('/login');
}, [navigate]);


const handleOnClick = useCallback(() => {
  handleLogout();
}, [handleLogout]);

  return (
    <Box>
      
        <Grid container>
          <Typography
            onClick={handleChangePassword}
            sx={{ cursor: 'pointer', marginBottom: '2rem' }}
            data-testid="change-password"
          >
            Change Password
          </Typography>
        </Grid>
      

      <Grid container>
        <Typography
          onClick={handleOnClick}
          sx={{ cursor: 'pointer', marginBottom: `${true ? 1 : 2}rem` }}
          data-testid="sign-out"
        >
          Sign Out
        </Typography>
      </Grid>
      {/* <CloseSupportSessionBeforeLogoutDialog open={isPopupOpen} onClose={handlePopupClose} /> */}
      {true ? (
        <Grid container>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              gap: 1,
            }}
          >
          
            <TermsOfServiceAndPrivacyPolicyOptimized
              labelEntry='Terms of Service'
            />
            <TermsOfServiceAndPrivacyPolicyOptimized labelEntry='Privacy Policy' />
          </Box>
        </Grid>
      ) : (
        <TermsOfServiceAndPrivacyPolicy />
      )}
      {openChangePassModal && <ChangePasswordDialogView handleClose={handleChangePassword} />}
    </Box>
  );
};

export default DrawerFooter;

