// import { useTranslation } from 'react-i18next';
// import Box from '@carrier-io/air-react/Box';
import { Theme } from '@mui/material/styles';
// import TextField from '@carrier-io/air-react/TextField';
// import Button from '@carrier-io/air-react/Button';
import React, { useMemo } from 'react';
import Box from '@carrier-io/air-react/Box';
import TextField from '@carrier-io/air-react/TextField';
import Button from '@carrier-io/air-react/Button';

import { useEditUserProfile } from '../utils/useEditUserProfile';

import { PhoneNumber } from './PhoneNumber';

const mobilePhoneFieldName = 'Mobile Phone';
const phoneFieldName = 'Office Phone';

interface EditUserProfileProps {
  onClose: () => void;
}

const textFieldStyles = {
  width: '50%',
  pb: 2,
  pr: 1,
  '& .MuiFormHelperText-root': {
    lineHeight: (theme: Theme): string => theme.spacing(2),
    position: 'absolute',
    left: 0,
    bottom: (theme: Theme): string => theme.spacing(-0.5),
  },
};

export const EditUserProfile = ({ onClose }: EditUserProfileProps): JSX.Element => {
//   const { t } = useTranslation();

  const {
    values,
    handleChange,
    handleBlur,
    firstNameError,
    lastNameError,
    phoneError,
    mobilePhoneError,
    isSaveDisabled,
    handleSave,
    isSubmitting,
    handlePhoneChange,
    handleMobilePhoneChange,
    isEditEnabled,
  } = useEditUserProfile();

  const readonly = useMemo(() => isSubmitting || !isEditEnabled, [isSubmitting, isEditEnabled]);

  return (
    <Box pt={2}>
      <Box display="flex" justifyContent="space-between" mb={0.5}>
        <TextField
          id="firstName"
          name="firstName"
          label='First Name'
          aria-label='user.management.first-name'
          size="large"
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!firstNameError}
          helperText={firstNameError}
          value={values.firstName}
          disabled={readonly}
          sx={textFieldStyles}
        />
        <TextField
          id="lastName"
          name="lastName"
          label='Last Name'
          aria-label='user.management.last-name'
          size="large"
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!lastNameError}
          helperText={lastNameError}
          value={values.lastName}
          disabled={readonly}
          sx={{ ...textFieldStyles, pr: 0 }}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" mb={2.5}>
        <TextField
          id="email"
          name="email"
          label='Email'
          aria-label='Email'
          type="email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          size="large"
          fullWidth
          disabled
        />
      </Box>
      <Box mb={1.5}>
        <PhoneNumber
          id={phoneFieldName}
          value={values.phone || ''}
          size="small"
          label='Office Phone'
          onChange={handlePhoneChange}
          error={!!phoneError}
          errorMessage={phoneError}
          fullWidth
          disabled={readonly}
        />
      </Box>
      <Box mb={2}>
        <PhoneNumber
          id={mobilePhoneFieldName}
          value={values.mobilePhone || ''}
          size="small"
          label='Mobile Phone'
          onChange={handleMobilePhoneChange}
          error={!!mobilePhoneError}
          errorMessage={mobilePhoneError}
          fullWidth
          disabled={readonly}
        />
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Button color="secondary" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="outlined"
          sx={{
            ml: 1,
          }}
          disabled={isSaveDisabled || readonly}
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
