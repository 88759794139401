import React, { memo } from 'react';
import { Container } from '@mui/material';

import AppBarTopHeader from '@carrier-io/air-react/patterns/AppBarTopHeader';
import {NavBar} from './NavBar';

import { HeaderRight } from './HeaderRight';
import { HeaderLeft } from './HeaderLeft';

import "../styles/AdminPage.css";

export const AdminPage = memo(() => (
  <Container className="header">
  <AppBarTopHeader

    appBarProps={{

      sx: {
        boxShadow: 'none',
        zIndex: 13000,
      },
    }}
    toolbarProps={{
      disableGutters: true,
      variant: 'dense',
      sx: {
        backgroundColor: 'primary.dark',
      },
    }}
    leftJustifiedGridProps={{ className: 'app-bar-left' }}
    rightJustifiedGridProps={{ className: 'app-bar-right' }}
    leftJustifiedComponent={<HeaderLeft />}
    rightJustifiedComponent={<HeaderRight />}
  />
  < NavBar />
  
</Container>

));

export default AdminPage;