import React, { useState, useCallback } from 'react';
import { Collapse, Box } from '@mui/material';
// import { useTranslation } from 'react-i18next';
// import Chip from '@carrier-io/air-react/Chip';

import { DrawerFooter } from './DrawerFooter';

import { UserNavigationItem } from './UserNavigationItem';
// import { Notifications } from './tabs/Notifications';
// import { Preferences } from './tabs/Preferences';
import { TabHeader } from './TabHeader';
import { ExternalApiKey } from './ExternalApiKey';
// import { UserNavigationTabs } from './tabs/types';
import { EditUserProfile } from './EditUserProfile';

// import { useUserSettings } from '@/providers/UserSettings';
import { Dialog } from './Dialog';
// import { useToggle } from '@/hooks';

export type UserNavigationTabs = 'main'  | 'externalApiKey';


interface UseToggle {
  value: boolean;
  toggle: () => void;
  toggleOn: () => void;
  toggleOff: () => void;
}

export const useToggle = (initialValue: boolean): UseToggle => {
  const [value, setValue] = useState(initialValue);

  const toggle = useCallback(() => setValue((prevValue: boolean) => !prevValue), []);

  const toggleOn = useCallback(() => setValue(true), []);

  const toggleOff = useCallback(() => setValue(false), []);

  return {
    value,
    toggle,
    toggleOn,
    toggleOff,
  };
};


export const UserNavigation = (): JSX.Element => {
//   const { t } = useTranslation();

//   const { userSettings, loading: userSettingsLoading } = useUserSettings();
//   const { notificationEnabled, notificationSMSEnabled } = userSettings;

//   const isNotificationPartiallyEnabled = notificationEnabled || notificationSMSEnabled;
  const [currentTab, setCurrentTab] = useState<UserNavigationTabs>('main');
  const [open, setOpen] = useState(true);

  const {
    value: openEditProfileDialog,
    toggleOn: handleOpenEditProfileDialog,
    toggleOff: handleCloseEditProfileDialog,
  } = useToggle(false);

  const setCurrentTabToMain = (): void => {
    setCurrentTab('main');
    setOpen(true);
  };

  const handleClickButton = (tab: UserNavigationTabs): void => {
    setCurrentTab(tab);
    setOpen(false);
  };

  return (
    <>
      <Collapse collapsedSize={0} in={open} orientation="vertical" unmountOnExit timeout="auto">
        {currentTab === 'main' && (
          <>
            <Box sx={{ pb: 1 }}>
              <UserNavigationItem
                title='Edit Profile'
                testId="user-edit-profile"
                onClick={() => handleOpenEditProfileDialog()}
              />
              {/* <UserNavigationItem
                title={t('user.profile.tab.notifications')}
                testId="user-profile-notifications"
                onClick={() => handleClickButton('notifications')}
                chip={
                  isNotificationPartiallyEnabled ? (
                    <Chip
                      label={t('common.on')}
                      color="primary"
                      size="small"
                      lightBackground
                      data-testid="notification-status"
                    />
                  ) : (
                    <Chip
                      label={t('common.off')}
                      color="error"
                      size="small"
                      lightBackground
                      data-testid="notification-status"
                    />
                  )
                }
                chipLoading={userSettingsLoading}
              /> */}
              <UserNavigationItem
                title='External API Key'
                testId="user-profile-external-api-key"
                onClick={() => handleClickButton('externalApiKey')}
              />
            </Box>
            <DrawerFooter />
          </>
        )}
      </Collapse>
      {/* <Collapse collapsedSize={0} in={!open} orientation="vertical" timeout="auto">
        {currentTab === 'preferences' && (
          <>
            <TabHeader
              title={t('user.profile.tab.preferences')}
              onClick={setCurrentTabToMain}
              data-testid="user-profile-preferences-back"
            />
            <Preferences />
          </>
        )}
      </Collapse> */}
      {currentTab === 'externalApiKey' && (
        <Box>
          <TabHeader
            title='External API Key'
            onClick={setCurrentTabToMain}
            data-testid="user-profile-external-api-key-back"
          />
          <ExternalApiKey />
        </Box>
      )}
      {openEditProfileDialog && (
        <Dialog
          open
          onClose={handleCloseEditProfileDialog}
          maxWidth="md"
          dialogTitle='Edit Profile'
          fullWidth
          content={<EditUserProfile onClose={handleCloseEditProfileDialog} />}
          PaperProps={{ sx: { width: '33%' } }}
        />
      )}
    </>
  );
};

export default UserNavigation;
