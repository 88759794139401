import React from "react";
import {Box, CssBaseline, ThemeProvider} from "@mui/material";

import AppRoutes from "./routes";
import theme from "./theme";
import styles from "./styles/App.module.css";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box className={styles.body}>
        <AppRoutes />
      </Box>
    </ThemeProvider>
  );
};

export default App;
