import React, { FC, useCallback, useEffect, useState } from 'react';
// import TextField, { TextFieldInputProps } from '@carrier-io/air-react/TextField';
import IconButton from '@carrier-io/air-react/IconButton';
import { Visibility, VisibilityOff, Key } from '@mui/icons-material';
import Grid from '@carrier-io/air-react/Grid';
import Box from '@carrier-io/air-react/Box';
import ButtonBase from '@carrier-io/air-react/ButtonBase';
import Typography from '@carrier-io/air-react/Typography';
import TextField, { TextFieldInputProps } from '@carrier-io/air-react/TextField';

// import { useTranslation } from 'react-i18next';
// import { UserService } from '@lynx/lynxfleet-fe-fdl';
// import { Maybe } from '@lynx/lynxfleet-types';

// import { Loader } from '@/components';
import { CopyToClipboard } from './CopyToClipboard';
// import { getAuthUserEmail } from '@/features/authentication';
// import { useAppSelector } from '@/stores';

export const ExternalApiKeyInput: FC = () => {
  const [key, setKey] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showKey, setShowKey] = useState<boolean>(false);

  // const loadKey = useCallback(async (): Promise<void> => {
  //   if (!authUserEmail) {
  //     setError(true);

  //     return;
  //   }

  //   setLoading(true);

  //   try {
  //     const getResponse = await UserService.getUserApiKey({ email: authUserEmail });
  //     const { data, error: getApiKeyError } = getResponse.data.getApiKey;

  //     if (getApiKeyError === null) {
  //       setKey((data && data[0]?.key) || null);
  //       setLoading(false);

  //       return;
  //     }
  //   } catch {
  //     setError(true);
  //     setLoading(false);

  //     return;
  //   }

  //   try {
  //     const createResponse = await UserService.createUserApiKey({ email: authUserEmail });

  //     if (createResponse.data) {
  //       const { data, error: createApiKeyError } = createResponse.data.createApiKey;

  //       if (createApiKeyError === null) {
  //         setKey(data?.key || null);
  //       } else {
  //         setError(true);
  //       }
  //     }

  //     setLoading(false);
  //   } catch {
  //     setError(true);
  //     setLoading(false);
  //   }
  // }, [authUserEmail]);

  const generateNewKey = async (): Promise<void> => {}

//   const generateNewKey = async (): Promise<void> => {
//     if (!authUserEmail) {
//       return;
//     }

//     setKey(null);
//     setLoading(true);

//     try {
//       const deleteResponse = await UserService.deleteUserApiKey({ email: authUserEmail });

//       if (!deleteResponse.data) {
//         return;
//       }

//       const { error: deleteApiKeyError } = deleteResponse.data.deleteApiKey;

//       if (deleteApiKeyError !== null) {
//         setLoading(false);
//         setError(true);

//         return;
//       }
//     } catch {
//       setError(true);
//       setLoading(false);

//       return;
//     }

//     try {
//       const createResponse = await UserService.createUserApiKey({ email: authUserEmail });

//       if (createResponse.data) {
//         const { data: createData, error: createError } = createResponse.data.createApiKey;

//         if (createError === null) {
//           setKey(createData?.key || null);
//         } else {
//           setError(true);
//         }
//       }

//       setLoading(false);
//     } catch (e) {
//       setLoading(false);
//       setError(true);
//     }
//   };

  // useEffect(() => {
  //   loadKey();
  // }, [loadKey]);

  const changeKeyVisible = (): void => {
    setShowKey((prevValue) => !prevValue);
  };

  const keyInput: any = {
    endAdornment: (
      <IconButton onClick={changeKeyVisible}>
        {showKey ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    ),
    type: showKey ? 'text' : 'password',
  };

//   if (loading) {
//     return <Loader />;
//   }

  return (
    <Box>
      {false ? (
        <Grid container>
          <Typography variant="caption" color="error.dark">
            'user.profile.external-api-key.error'
          </Typography>
        </Grid>
      ) : (
        <>
          <TextField
            inputSetting={keyInput}
            defaultValue= 'test123'//{key}
            helperText='Copy this key to use API'
            fullWidth
          />
          <Box display="flex" justifyContent="space-between" sx={{ marginTop: '1.375rem' }}>
            <CopyToClipboard label='Copy Key' text={key || ''} />
            <ButtonBase onClick={generateNewKey}>
              <Key sx={{ marginRight: '.5rem', color: 'primary.main' }} />
              <Typography color="primary" variant="buttonMedium">
                New Key
              </Typography>
            </ButtonBase>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ExternalApiKeyInput;
