import React, { useState, MouseEvent } from 'react';
import { Box, IconButton, Menu } from '@mui/material';
// import IconButton from '@carrier-io/air-react/IconButton';
// import Menu from '@carrier-io/air-react/Menu';
//import { useRbac } from '@carrier-io/rbac-provider-react';

import { HeaderMenuIcon } from '../icons/HeaderMenuIcon';
// import { ApiPortalButton } from './ApiPortalButton';

// import { getAuthUserEmail } from '@/features/authentication';
// import { useAppSelector } from '@/stores';
// import { actionPayload } from '@/features/authorization';
import { UserSettings } from './UserSettings';

import "../styles/AdminPage.css";


export const HeaderRight = (): JSX.Element => {
//   const authUserEmail = useAppSelector(getAuthUserEmail);

//   const { hasPermission } = useRbac();

//   const canAccessApiPortal = hasPermission(
//     actionPayload({
//       action: 'apiPortal.view',
//       subjectType: 'USER',
//       subjectId: authUserEmail,
//     })
//   );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuButtonClick = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (): void => setAnchorEl(null);

//   const showButton = Boolean(canAccessApiPortal);

  return (
    <>
      <Box className="app-bar-right__settings">
        <UserSettings fullName = 'Dev Admin' email='dev@carrier.com'/>
      </Box>

     
    </>
  );
};

export default HeaderRight;
