import { useState } from 'react';
import * as Yup from 'yup';
import { FormikProps, useFormik } from 'formik';

import { useEmailValidationSchema } from '../utils/useEmailValidationSchema';

const initialValues = {
  username: '',
  password: '',
  staySignedIn: false,
};

interface IUseLoginForm {
  loginForm: FormikProps<{
    username: string;
    password: string;
    staySignedIn: boolean;
  }>;
  loginLoading: boolean;
}

export const useLoginForm = (userSpecifiedPath: string): IUseLoginForm => {
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    username: useEmailValidationSchema(),
    password: Yup.string()
      .required('Password is required')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
    },
  });

  return { loginForm: formik, loginLoading: loading };
};
