import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';

import { useEmailValidationSchema } from '../utils/useEmailValidationSchema';

const initialValues = {
  email: '',
};

type Status = {
  type: 'loading' | 'success' | 'error' | null;
  message: string;
};

export const useForgotPassword = (): FormikProps<{ email: string }> => {
  const [status, setStatus] = useState<Status>({ type: null, message: '' });

  const validationSchema = Yup.object().shape({
    email: useEmailValidationSchema(),
  });

  return {
    ...useFormik({
      initialValues,
      validationSchema,
      validateOnChange: false,
      validateOnBlur: true,
      onSubmit: (values, { setSubmitting }) => {
        setStatus({ type: 'loading', message: '' });
        setTimeout(() => {
          setStatus({ type: 'success', message: 'Password reset link sent!' });
          setSubmitting(false);
        }, 2000);
      },
    }),
    status,
  };
};
