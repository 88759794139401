import { SignInForm } from '@carrier-io/air-react/patterns/SignIn/SignInForm';
import React, { useState, KeyboardEvent, FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLoginForm } from './useLoginForm';

interface Path {
  userSpecifiedPath: string;
}

export const LoginForm: FC<Path> = ({ userSpecifiedPath }): JSX.Element => {
  const { loginForm } = useLoginForm(userSpecifiedPath);
  const { values, errors, handleChange, handleBlur, submitForm } = loginForm;

  const [formErrors, setFormErrors] = useState({
    username: '',
    password: '',
  });

  const navigate = useNavigate();

  const handleSubmit = (): void => {
    setFormErrors({ username: '', password: '' });
    let hasError = false;
    if (!values.username) {
      setFormErrors((prev) => ({ ...prev, username: 'Email is required' }));
      hasError = true;
    }

    if (!values.password) {
      setFormErrors((prev) => ({ ...prev, password: 'Password is required' }));
      hasError = true;
    }

    if (hasError) {
      console.error('Form submission blocked due to missing fields');
      return;
    }

    if (!errors.username && !errors.password) {
      localStorage.setItem('email', values.username);
      console.log('Email and password saved to local storage', values.username);

      submitForm();
      navigate('/admin');
    } else {
      console.error('Validation errors exist, unable to save email');
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLElement>): void => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const disallowedCharacters = ['=', '+', '-', '@', '\t', '\r'];

  const handleChangeWithValidation = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    if (disallowedCharacters.includes(value.charAt(0))) {
      console.error('Invalid character entered at the beginning of the input');
      return;
    }
    handleChange(event);
  };

  return (
    <SignInForm
      showStaySignIn={false}
      EmailInputProps={{
        name: 'username',
        onChange: handleChangeWithValidation,
        onBlur: handleBlur,
        onKeyPress: handleKeyPress,
        value: values.username,
        label: 'Email',
        error: !!errors.username || !!formErrors.username,
        helperText: formErrors.username || (errors.username ?? ''),
        size: 'xlarge',
        sx: {
          borderRadius: '4px'
        },
      }}
      PasswordInputProps={{
        name: 'password',
        value: values.password,
        label: 'Password',
        onChange: handleChangeWithValidation,
        onBlur: handleBlur,
        onKeyPress: handleKeyPress,
        error: !!errors.password || !!formErrors.password,
        helperText: formErrors.password || (errors.password ?? ''),
        size: 'xlarge',
        sx: {
          borderRadius: '4px',
          '&.MuiButton-root': {
            'margin-top': 0,
            padding: '15px',
          },
        },
      }}
      SubmitButtonProps={{
        label: 'Sign In',
        onClick: handleSubmit,
        size: 'xlarge',
        sx: {
          '&.MuiButton-root': {
            'margin-top': 0,
            padding: '15px',
          },
        },
      }}
    />
  );
};
