import {
    createContext,
    useContext,
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useState,
  } from 'react';

  export type UserSettings = {
    email: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    timezone?: string;
    phone?: string;
    mobilePhone?: string;
  };
  export const defaultUserSettings: UserSettings = {
    email: 'dev@carrier.com',
    firstName: 'Test',
    lastName: 'Carrier',
    fullName: 'Test Carrier',
    
  };
  

  export interface UserSettingsContextInterface {
    userSettings: UserSettings;
    onUserSettingsChange: (key: string, value: unknown) => void;
    onBatchUserSettingsChange: (
      userSettings: any
    ) => Promise<any>;
    loading: boolean;
  }
  
export const UserSettingsContext = createContext<UserSettingsContextInterface>({
    userSettings: defaultUserSettings,
    onUserSettingsChange: () => {},
    onBatchUserSettingsChange: () => Promise.resolve({ success: true, error: null }),
    loading: false,
    // hasAccessToLocationData: false,
    // hasAccessToUserLevelAddressVisibility: false,
  });
  
  export const useUserSettings = (): UserSettingsContextInterface => {
    const context = useContext(UserSettingsContext);
  
    return context;
  };