import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import LoginPage from './components/LoginPage';
import AdminPage from './components/AdminPage';
import CompanyManagement from "./components/CompanyManagement";


const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/company-management" element={<CompanyManagement />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
