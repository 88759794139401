import React, { useEffect, useState, useCallback } from 'react';
import { MuiTelInput, MuiTelInputInfo, MuiTelInputProps } from 'mui-tel-input';
import { Box, Typography} from '@mui/material';

import { Theme } from '@carrier-io/air-react/styles';

type PhoneNumberProps = MuiTelInputProps & {
  id: string;
  error?: boolean;
  errorMessage?: string;
};
export const PhoneNumber = ({
  value,
  defaultCountry = 'US',
  label,
  placeholder = '+1 212 555 1212',
  onChange,
  error,
  errorMessage,
  size = 'small',
  ...restProps
}: PhoneNumberProps): JSX.Element => {
  
  const [shrink, setShrink] = useState(true);
  const [changed, setChanged] = useState(false);
  const inputLabel = label || 'common.components.phone-number';

  useEffect(() => {
    if (value) {
      setShrink(true);
    }
  }, [value]);

  const handleChange = useCallback(
    (val: string, info: MuiTelInputInfo) => {
      if (onChange) {
        setChanged(true);
        onChange(val, info);
      }
    },
    [onChange]
  );

  return (
    <Box pb={2} sx={{ position: 'relative' }}>
      <MuiTelInput
        value={value}
        defaultCountry={defaultCountry}
        label={inputLabel}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={() => {
          if (!value && changed) {
            setShrink(false);
          }
        }}
        onClick={() => setShrink(true)}
        error={error}
        InputLabelProps={{
          shrink,
        }}
        size={size}
        // @ts-ignore
        sx={{
          '& .MuiInputBase-root': {
            paddingLeft: (theme: Theme) => theme.spacing(1.5),
            backgroundColor: (theme: Theme) => `${theme.palette.action.hover} !important`,
          },
          '& .MuiFormLabel-root': {
            top: shrink ? 0 : (theme: Theme): string => theme.spacing(0.5),
            left: shrink ? 0 : (theme: Theme): string => theme.spacing(8),
            visibility: value && !shrink ? 'hidden' : 'visible',
          },
          '& .MuiFormLabel-root.MuiInputLabel-shrink': {
            top: (theme: Theme) => theme.spacing(1.5),
            left: (theme: Theme) => theme.spacing(7.75),
          },
          '& .MuiInputBase-root input': {
            height: (theme: Theme) => (size === 'medium' ? theme.spacing(5.5) : theme.spacing(4)),
            paddingTop: (theme: Theme) => theme.spacing(1.5),
            paddingBottom: '1px',
            paddingLeft: 2,
            paddingRight: 0,
          },
          '& .MuiTelInput-Flag img': {
            width: (theme: Theme) => theme.spacing(3),
            height: (theme: Theme) => theme.spacing(2),
          },
          '& .MuiTelInput-Flag::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            marginTop: '-1px',
            left: '45px',
            width: '6px',
            height: '8px',
            borderLeft: '3px solid transparent',
            borderRight: '3px solid transparent',
            borderTop: '4px solid #555',
          },
          '& .MuiInputBase-root fieldset': {
            border: '1px solid transparent !important',
          },
        }}
        {...restProps}
      />
      {error && (
        <Typography
          variant="body1"
          color="error"
          sx={{
            position: 'absolute',
            bottom: (theme) => theme.spacing(-0.5),
            left: 0,
            pl: 1.5,
            lineHeight: (theme) => theme.spacing(2),
          }}
        >
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

export default PhoneNumber;
