import TermsOfService, {
  TermsOfServiceProps,
} from '@carrier-io/air-react/patterns/SignIn/TermsOfService';
import React, { FC } from 'react';

import { PRIVACY_POLICY_PDF_EN, TERMS_OF_SERVICE_PDF_EN } from '../constants/policy';

export const TermsOfServiceAndPrivacyPolicyOptimized: FC<Partial<TermsOfServiceProps>> = (
  props
) => {
  const { labelEntry = 'Terms of Service & Privacy Policy' } = props;

  const linkToPDF =
    labelEntry === 'Privacy Policy' ? PRIVACY_POLICY_PDF_EN : TERMS_OF_SERVICE_PDF_EN;

  return (
    <TermsOfService
      acknowledge
      labelClose="Close"
      labelDownloadPDF="Download as PDF"
      labelEntry={labelEntry}
      sxModalTitle={{
        paddingLeft: '45px!important',
        paddingBottom: '15px!important',
      }}
      sxModalContent={{
        '& .MuiTabs-root': {
          display: 'none!important',
        },
      }}
      labelApprove="Accept"
      labelReject="Decline"
      labelAcknowledgement="I acknowledge that I have read the above and agree to the Terms of Use & Privacy Policy"
      tabsConfiguration={[
        {
          id: '1',
          // @ts-ignore
          content: (
            <iframe
              width={900}
              height={490}
              src={`${linkToPDF}#toolbar=0`}
              title={labelEntry}
              data-testid="policy-privacy-frame"
            />
          ),
          linkToPDF,
        },
      ]}
      title={labelEntry}
      {...props}
      sxModalActions={{
        '&.MuiDialogActions-root': {
          paddingLeft: 5,
        },
      }}
    />
  );
};
