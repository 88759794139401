import React from 'react';
import { Box, Typography } from '@mui/material';

import { LynxLogoIcon } from '../icons/LynxLogoIcon';


export const HeaderLeft = (): JSX.Element => (
  <Box display="flex">
      <LynxLogoIcon sx={{ width: '32px', height: '32px' }} fill="white" />
    <Typography 
      variant="subtitle1" 
      color="white" 
      sx={{ marginLeft: '24px', 'font-size':'20px' ,fontFamily: "Nunito Sans, Helvetica, Arial, sans-serif" }}
    >
    Admin Portal
    </Typography>
  </Box>
);

export default HeaderLeft;